// change the theme
$theme-colors: (
        "primary": #4885b2,
        "danger": #ff4136,
        "secondary": grey,
        "success": #116611,
        "warning": yellow,
        "info": teal,
        "light": #BBB,
        "dark": #444,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

body {
  background-color: #efefef;
  padding-bottom: 70px;
}

.form-control {
  margin-bottom: 10px;
}

.navbar .btn {
  color: white;
}

.btn-primary {
  color: white;
}

.btn-outline-primary:hover {
  color: white;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  color: #333;
  text-decoration: none;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.dropdown-item span:first-child {
  margin-right: 10px;
  font-weight: bold;
}
